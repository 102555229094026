<template>
  <div
    class="v-input"
    :class="{ 'v-input_error': error, 'v-input_border': border, 'v-input_disabled': disabled}"
    :style="{backgroundColor: bg, color: color}"
  >
    <span class="v-input__icon-left" v-if="$slots.iconLeft">
      <slot name="iconLeft"></slot>
    </span>
    <input
      type="text"
      class="v-input__input"
      :placeholder="placeholder"
      v-model="inputVal"
      :disabled="disabled || readonly"
    />
    <span class="v-input__icon-right" v-if="$slots.iconRight">
      <slot name="iconRight"></slot>
    </span>
  </div>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    modelValue: [String, Number],
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: String,
      default: '#FFFFFF'
    },
    color: {
      type: String,
      default: '#1E3959'
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const inputVal = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
  });
</script>

<style scoped lang="scss">
  .v-input {
    width: 300px;
    height: 48px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: #fff;

    &_border {
      border: 1px solid $light-gray-1;
    }

    &_error {
      border: 1px solid $red;
    }

    &__icon-left {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
    }

    &__icon-right {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      cursor: pointer;
    }

    &__input {
      width: 100%;
      height: 100%;
      padding-left: 12px;
      background-color: transparent;
      color: $dark;
      font-size: 14px;
      font-weight: 400;

      &::placeholder {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &_disabled  &__input {
      color: $gray;
    }
  }
</style>

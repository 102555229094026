<template>
    <div class="v-file">
        <div class="v-file__content">
            <img src="@/assets/images/icons/image.svg" alt="document">
            <div class="v-file__text">
                <div class="v-file__title" v-if="file.file_name || file.name">{{ file.file_name ? file.file_name : file.name }}</div>
                <div class="v-file__title" v-if="file.filename">{{ file.filename }}</div>
                <div class="v-file__size">{{ file.size }} {{!hideSize ? 'МБ' : ''}} </div>
            </div>
        </div>

        <div class="v-file__delete" v-if="deleteBtn" @click="emit('delete')">
            <img src="@/assets/images/icons/delete-red.svg" alt="delete">
        </div>

        <a :href="file.filepath" download v-else>
            <img src="@/assets/images/icons/download-blue.svg" alt="download">
        </a>
    </div>
</template>

<script setup>
defineProps({
    file: Object,
    hideSize: {
        type: Boolean,
        default: false
    },
    deleteBtn: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['delete'])
</script>

<style scoped lang="scss">
.v-file {
    height: 56px;
    padding: 0 12px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $light;

    &__content {
        display: flex;
        align-items: center;
    }

    &__text {
        margin-left: 12px;
    }

    &__title {
        width: 95px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__size {
        margin-top: 4px;
        color: $gray;
        font-size: 12px;
    }

    &__delete {
        width: 20px;
        height: 20px;
        cursor: pointer;

        img {
            width: 100%;
        }
    }
}
</style>

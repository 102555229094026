import MainLayout from '../layouts/MainLayout';

const routes = [
    {
        path: '/companies',
        meta: { role: 'SUPER_ADMIN', layout: MainLayout, name: 'Компании' },
        children: [
            {
                path: '',
                name: 'Companies',
                component: () => import('../views/companies')
            },
            {
                path: ':id',
                name: 'Company',
                meta: { name: 'Карточка компании' },
                children: [
                    {
                        path: '',
                        name: 'Company',
                        component: () => import('../views/company'),
                    },
                    {
                        path: 'create-employee',
                        name: 'CreateEmployeeAndAdmin',
                        meta: { name: 'Новый сотрудник' },
                        component: () => import('../views/createEmployee'),
                    },
                    {
                        path: 'edit/:employeeId',
                        name: 'EditEmployeeSA',
                        meta: { name: 'Редактировать сотрудника' },
                        component: () => import('../views/createEmployee'),
                    },
                    {
                        path: 'edit-company',
                        name: 'EditCompany',
                        meta: { name: 'Редактировать компанию' },
                        component: () => import('../views/createCompany')
                    },
                ]
            },
            {
                path: 'new',
                name: 'NewCompany',
                meta: { name: 'Новая компания' },
                component: () => import('../views/createCompany'),
            },
            // {
            //     path: 'edit/:id',
            //     name: 'EditCompany',
            //     meta: { name: 'Редактировать компанию' },
            //     component: () => import('../views/createCompany')
            // },
        ]
    },
    {
        path: '/super-admins',
        meta: { role: 'SUPER_ADMIN', layout: MainLayout, name: 'Администраторы' },
        children: [
            {
                path: '',
                name: 'SuperAdmins',
                component: () => import('../views/superAdmins')
            },
            {
                path: 'new',
                name: 'NewAdmin',
                meta: { name: 'Новый администратор' },
                component: () => import('../views/createAdmin'),
            },
            {
                path: ':id/edit',
                name: 'EditAdmin',
                meta: { name: 'Редактировать администратора' },
                component: () => import('../views/createAdmin'),
            },
        ]
    },
    {
        path: '/specialties',
        name: 'Specialties',
        meta: { role: 'SUPER_ADMIN', layout: MainLayout },
        component: () => import('../views/specialties')
    },
]

export default routes

<template>
  <div class="v-tooltip" :class="`v-tooltip_${position}`">
    <div
      class="v-tooltip__icon"
      @mousemove="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <slot name="icon"></slot>
    </div>
    <transition name="fade">
      <div
        class="v-tooltip__message"
        :class="{'v-tooltip__message_auto-width': autoWidth}"
        :style="{ backgroundColor: bg, color: color }"
        v-if="showTooltip"
      >
        <span class="v-tooltip__message-corner">
          <svg
            width="28"
            height="8"
            viewBox="0 0 28 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5858 1.41421L9.74904 4.25097C8.36542 5.63458 7.67361 6.32639 6.86628 6.82112C6.1505 7.25975 5.37015 7.58299 4.55386 7.77896C3.63316 8 2.65479 8 0.698068 8H27.3019C25.3452 8 24.3668 8 23.4461 7.77896C22.6299 7.58299 21.8495 7.25975 21.1337 6.82112C20.3264 6.32639 19.6346 5.63458 18.251 4.25097L15.4142 1.41421C14.6332 0.633163 13.3668 0.633165 12.5858 1.41421Z"
              :fill="bg"
            />
          </svg>
        </span>
        {{ text }}
      </div>
    </transition>
  </div>
</template>

<script setup>
  import { ref } from "vue";

  defineProps({
    text: {
      type: String,
      required: true,
    },
    bg: {
      type: String,
      default: "#446290",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left'
    }
  });

  const showTooltip = ref(false);
</script>

<style scoped lang="scss">
  .v-tooltip {
    position: relative;
    z-index: 99;

    &__message {
      position: absolute;
      right: -14px;
      top: 27px;
      width: 205px;
      padding: 11.5px 16px;
      border-radius: 8px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      z-index: 99;

      &_auto-width {
        width: auto;
        white-space: nowrap;
      }
    }

    &_center &__message {
      right: 50%;
      transform: translate(50%);
    }

    &__message-corner {
      position: absolute;
      top: -13px;
      right: 8px;
    }

    &_center  &__message-corner {
      left: 7px;
    }
  }
</style>

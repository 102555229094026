const state = {
    token: null,
    role: null,
    profileInfo: null
};
const mutations = {
    AUTH: (state, data) => {
        state.token =  data.token
        state.role = data.role
    },
    LOGOUT: (state) => {
        state.token = null
        state.role = null
    },
    PROFILE: (state ,data) => {
        state.profileInfo = data
    }
};
const actions = {
    auth({commit}, data) {
        commit("AUTH", data)
        localStorage.setItem("token", data.token)
        localStorage.setItem("role", data.role)
    },
    logout({commit}) {
        commit('LOGOUT')
        localStorage.clear();
    },
    setProfile({commit}, data){
        commit("PROFILE", data)
    }
};

const getters = {
    token: (state) => state.token,
    role: (state) => state.role,
    profileInfo: (state) => state.profileInfo
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div class="v-input" :class="{ 'v-input_error validation-error': error, 'v-input_date': inputType === 'date' }">
    <span
        class="v-input__placeholder"
        :class="{ 'v-input__placeholder_focus': focus || inputVal }"
    >{{ placeholder }}
    </span>
        <input
            ref="inpRef"
            :type="inputType"
            class="v-input__input"
            :class="{ 'v-input__input_focus': focus || inputVal }"
            v-model="inputVal"
            @focus="focusInp"
            @blur="blurInp"
            :autocomplete="type === 'password' ? 'new-password' : 'on'"
            :maxlength="maxlength"
            :disabled="disabled"
        />
        <span
            class="v-input__icon"
            @click="togglePassword"
            v-if="type === 'password'"
        >
      <img
          src="@/assets/images/icons/eye-off.svg"
          alt="eye"
          v-if="showPassword"
      />
      <img src="@/assets/images/icons/eye.svg" alt="eye" v-else/>
    </span>
        <span v-if="$slots.icon" class="v-input__icon" :class="{'v-input__icon_disabled': disabled}">
      <slot name="icon"></slot>
    </span>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    modelValue: [String, Number],
    placeholder: {
        type: String,
    },
    type: {
        type: String,
        default: "text",
    },
    error: {
        type: Boolean,
        default: false,
    },
    maxlength: Number,
    disabled: {
        type: Boolean,
        default: false
    },
});

const emit = defineEmits(["update:modelValue", "focus", "blur"]);

const inputVal = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const focus = ref(false);
const showPassword = ref(false);
const inputType = ref(props.type);

const focusInp = () => {
    emit('focus')
    focus.value = true
}

const blurInp = () => {
    emit('blur')
    focus.value = false
}

const togglePassword = () => {
    showPassword.value = !showPassword.value;
    inputType.value = inputType.value === "password" ? "text" : "password";
};

const inpRef = ref(null)
onMounted(() => {
    if(props.type === 'date') {
        inpRef.value.max = "4000-01-01";
    }
})
</script>

<style scoped lang="scss">
.v-input {
    position: relative;
    width: 300px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: $light;

    &_error {
        border: 1px solid $red;
    }

    &__placeholder {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: $dark-blue;
        z-index: 1;
        font-size: 14px;
        transition: 0.3s;

        &_focus {
            top: 11px;
            color: $dark-blue;
            font-size: 12px;
        }
    }

    &__input {
        width: 100%;
        height: 100%;
        padding-left: 16px;
        padding-top: 16px;
        background-color: transparent;
        color: $dark;
        font-size: 14px;
        font-weight: 400;
        z-index: 1;

        &::-webkit-datetime-edit-fields-wrapper{
            opacity: 0;
        }

        &:focus {
            &::-webkit-datetime-edit-fields-wrapper{
                opacity: 1;
            }
        }

        &_focus {
            &::-webkit-datetime-edit-fields-wrapper{
                opacity: 1;
            }
        }
    }

    &_date &__input  {
        &::-webkit-calendar-picker-indicator {
            margin-bottom: 15px;
            width: 48px;
            height: 48px;
            background-image: url("../../../assets/images/icons/calendar.svg");
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    &__icon {
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-right: 12px;

        &_disabled {
            cursor: default;
        }

        &:last-child {
            margin-right: 16px;
        }
    }
}
</style>

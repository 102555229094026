<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close"/>
        </div>

        <div class="modal-body__title">Личные данные</div>

        <div class="modal-body__fields">
            <div class="modal-body__field">
                <div class="modal-body__field-label">ФИО:</div>
                <div class="modal-body__field-value">{{ profileInfo?.full_name?.value }}</div>
            </div>
            <div class="modal-body__field">
                <div class="modal-body__field-label">Уровень доступа:</div>
                <div class="modal-body__field-value">{{ profileInfo?.role?.value }}</div>
            </div>
            <div class="modal-body__field">
                <div class="modal-body__field-label">Email:</div>
                <div class="modal-body__field-value">{{ profileInfo?.email?.value }}</div>
            </div>
            <div class="modal-body__field">
                <div class="modal-body__field-label">Пароль:</div>
                <div class="modal-body__field-row">
                    <div class="modal-body__field-dots">
                        <span v-for="i in 8" :key="i"></span>
                    </div>

                    <div @click="emit('openChangePasswordModal')" class="modal-body__field-btn">Изменить пароль</div>
                </div>
            </div>
        </div>

        <div @click="openLogout" class="modal-body__logout">
            Выйти из аккаунта
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    profileInfo: Object
})

const emit = defineEmits(['close', 'openLogout', 'openChangePasswordModal'])

const openLogout = () => {
    emit('openLogout')
}
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    width: 352px;
    height: 363px;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;

    &__close {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    &__title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
    }

    &__fields {
        margin-top: 16px;
    }

    &__field {
        &:not(:first-child) {
            margin-top: 20px;
        }

        &-label {
            color: $gray;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
        }

        &-value {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
        }

        &-row {
            margin-top: 4px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
        }

        &-dots {
            display: flex;

            span {
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background-color: $dark;

                &:not(:first-child) {
                    margin-left: 8px;
                }
            }
        }

        &-btn {
            color: $blue;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            cursor: pointer;
        }
    }

    &__logout {
        margin-top: 32px;
        color: $red;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        cursor: pointer;
    }
}
</style>

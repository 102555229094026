<template>
    <div class="v-breadcrumbs">
        <div
            class="v-breadcrumbs__item"
            v-for="(route, index) in routes"
            :key="index"
        >
            <div class="v-breadcrumbs__name" v-if="route.name === $route.name">{{ route.meta.name }}</div>
            <router-link :to="route.path" class="v-breadcrumbs__name" v-if="route.name !== $route.name && customRouteIndex !== index">
                {{ route.meta.name }}
            </router-link>
            <router-link :to="customRouteLink" class="v-breadcrumbs__name" v-if="route.name !== $route.name && customRouteIndex === index">
                {{ route.meta.name }}
            </router-link>
            <img
                src="@/assets/images/icons/arrow-right-gray.svg"
                class="v-breadcrumbs__arrow"
                alt="arrow"
            />
        </div>
    </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import {onActivated, onDeactivated, ref} from "vue";

const route = useRoute();
const routes = ref(route.matched)

defineProps({
    customRouteIndex: Number,
    customRouteLink: String
})

onActivated(() => {
    routes.value = route.matched
})
</script>

<style scoped lang="scss">
.v-breadcrumbs {
    display: flex;

    &__item {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-left: 4px;
        }
    }

    &__name {
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        text-decoration: none;
    }

    &__arrow {
        width: 12px;
        height: 12px;
        margin-left: 4px;
    }

    &__item:last-child &__arrow {
        display: none;
    }
}
</style>

<template>
    <div class="modal-body">
        <div class="modal-body__close" @click.prevent="$emit('close')">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 5L15 15" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </div>

        <h3 class="modal-body__title" v-html="title"></h3>
        <p class="modal-body__description" v-html="description"></p>

        <div class="modal-body__btns">
            <VButton label="Закрыть"
                     class="modal-body__btn"
                     clickEffect
                     @click.prevent="$emit('close')"/>
        </div>
    </div>
</template>

<script setup>
import {useRoute} from "vue-router";

defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
});

const route = useRoute();

const vacancyId = route.params.id;
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 28.5px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        max-width: 230px;
        margin-top: 16px;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }

    &__description {
        max-width: 230px;
        margin-top: 12px;
        text-align: center;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -.5px;
    }

    &__btns {
        width: 100%;
    }

    &__btn {
        width: 100%;
        margin-top: 24px;

        &:active {
            background-color: #1447AF !important;
            color: #fff !important;
        }
    }
}
</style>

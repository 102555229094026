<template>
    <div class="v-search-input">
        <div class="v-search-input__inp-wrapper">
            <input
                v-model="inputVal"
                :placeholder="placeholder"
                type="text"
                class="v-search-input__inp"
                @focus="showItems = true"
                @blur="blur">

            <div v-if="val" @click="clear" class="v-search-input__inp-clear">
                <img src="@/assets/images/icons/clear.svg" alt="clear">
            </div>
        </div>

        <div v-if="showItems && itemsList.length" class="v-search-input__items">
            <div
                v-for="(item, index) in itemsList"
                :key="index"
                @click="selectResult(item)"
                class="v-search-input__item">
                {{ item[labelFild] }}

                {{ item[subTitleFild] ? `(${item[subTitleFild]})` : '' }}
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    modelValue: [String, Number],
    placeholder: {
        type: String
    },
    error: {
        type: Boolean,
        default: false
    },
    results: {
        type: Array,
        default: []
    },
    labelFild: {
        type: String,
        default: 'label'
    },
    subTitleFild: {
        type: String,
        default: ''
    },
})

const emit = defineEmits(["update:modelValue"]);
const val = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});
const inputVal = ref('')

const showItems = ref(false);

const itemsList = computed(() => {
    return props.results.filter(item => item[props.labelFild].toUpperCase().indexOf(inputVal.value.toUpperCase()) >= 0)
})

watch(() => val.value, () => {
    if (val.value === null) inputVal.value = ''
})

const selectResult = (item) => {
    val.value = item.id
    inputVal.value = `${item[props.labelFild]} ${item[props.subTitleFild] ? item[props.subTitleFild] : ''}`
}

const clear = () => {
    val.value = null
    inputVal.value = ""
}

const blur = () => {
    setTimeout(() => {
        showItems.value = false
    }, 300)
}

onMounted(() => {
    if (val.value) {
        const slected = props.results.find(item => item.id === val.value)
        inputVal.value = slected?.[props.labelFild]
    }
})
</script>

<style scoped lang="scss">
.v-search-input {
    position: relative;

    &__inp-wrapper {
        position: relative;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        background-color: $light;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    &__inp {
        width: 100%;
        height: 100%;
        padding-left: 16px;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        color: $dark;

        &::placeholder {
            color: $dark-blue;
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__inp-clear {
        width: 16px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 2;

        img {
            width: 100%;
            margin-top: 3px;
        }
    }

    &__items {
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid $light-gray-1;
        box-shadow: 2px 4px 24px 0px rgba(0, 0, 0, 0.12);
        z-index: 2;
    }

    &__item {
        width: 100%;
        height: 40px;
        padding-left: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        user-select: none;
        transition: .1s;

        &:hover {
            background-color: $light-gray-1;
        }
    }
}
</style>

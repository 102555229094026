import MainLayout from '../layouts/MainLayout';

const routes = [
    {
        path: '/dl-tasks',
        meta: { role: 'DIRECTION_LEADER', layout: MainLayout, name: 'Задачи' },
        children: [
            {
                path: '',
                name: 'DLResponses',
                component: () => import('../views/responses'),
            },
            {
                path: ':id',
                name: 'DLResponse',
                meta: { name: 'Карточка задачи' },
                component: () => import('../views/response'),
            }
        ]
    },
]

export default routes

<template>
  <div class="modal" @click.self.prevent="$emit('close')">
    <transition name="bounce">
      <div class="modal__body" v-if="show">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";

  const show = ref(false);

  onMounted(() => (show.value = true));
</script>

<style scoped lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(51, 51, 51, 0.32);
    z-index: 100;

    &__body {
      width: 288px;
      border-radius: 16px;
      background-color: #fff;
    }
  }
</style>

import MainLayout from '../layouts/MainLayout';

const routes = [
    {
        path: '/sb-tasks',
        meta: { role: 'SECURITY_SERVICE', layout: MainLayout, name: 'Задачи' },
        children: [
            {
                path: '',
                name: 'SBResponses',
                component: () => import('../views/responses'),
            },
            {
                path: ':id',
                name: 'SBResponse',
                meta: { name: 'Карточка задачи' },
                component: () => import('../views/response'),
            }
        ]
    },
]

export default routes

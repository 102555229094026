<template>
  <div class="v-checkbox-label">
    <div class="v-checkbox-label__label">
      {{ label }}
      <VTooltip
          :text="tooltipText"
          class="v-checkbox-label__tooltip"
          v-if="tooltipText"
          autoWidth
          :position="tooltipPosition"
      >
        <template #icon>
          <img src="@/assets/images/icons/tooltip-gray-fill.svg" alt="tooltip"/>
        </template>
      </VTooltip>
    </div>
    <VCheckbox v-model="inputVal"/>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    required: true
  },
  tooltipText: String,
  tooltipPosition: {
    type: String,
    default: 'left'
  }
});

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>

<style scoped lang="scss">
.v-checkbox-label {
  height: 48px;
  padding: 0 12px 0 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $light;

  &__label {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__tooltip {
    margin-left: 8px;
    margin-top: 2px;
  }
}
</style>

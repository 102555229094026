import api from "./api";

const regions = {
    getProfile() {
        return api.get('/profile');
    },
    getLogout() {
        return api.get('/profile/logout');
    },
    postChangePassword(body) {
        return api.post('/profile/changePassword', body);
    },
};

export default regions;

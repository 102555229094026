import MainLayout from '../layouts/MainLayout';

const routes = [
    {
        path: '/responses',
        meta: { role: 'APPROVING_PERSON', layout: MainLayout, name: 'Задачи' },
        children: [
            {
                path: '',
                name: 'EmployeeTasks',
                redirect: "/responses",
            },
            {
                path: ':id',
                name: 'EmployeeTask',
                redirect: '/responses/:id',
                meta: { name: 'Карточка отклика' },
            }
        ]
    },
]

export default routes

import MainLayout from '../layouts/MainLayout';

const routes = [
    {
        path: '/employees',
        meta: {role: 'MAIN_HR', layout: MainLayout, name: 'Сотрудники'},
        children: [
            {
                path: '',
                name: 'Employees',
                component: () => import('../views/employees'),
            },
            {
                path: 'new',
                name: 'NewEmployee',
                meta: { name: 'Новый сотрудник' },
                component: () => import('../views/createEmployee'),
            },
            {
                path: 'edit/:employeeId',
                name: 'EditEmployee',
                meta: { name: 'Редактировать сотрудника' },
                component: () => import('../views/createEmployee'),
            },
        ]
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: { role: 'MAIN_HR', layout: MainLayout },
        component: () => import('../views/settings')
    },
]

export default routes

<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close"/>
        </div>

        <div class="modal-body__title">Изменение пароля</div>

        <div class="modal-body__form">
            <VCustomInput
                v-model="form.current_password"
                type="password"
                placeholder="Текущий пароль*"
                class="modal-body__inp"
                :error="v$.current_password.$error"
            />
            <VCustomInput
                v-model="form.password"
                type="password"
                placeholder="Новый пароль*"
                class="modal-body__inp"
                :error="v$.password.$error || matches"
            />
            <VCustomInput
                v-model="form.password_confirmation"
                type="password"
                placeholder="Новый пароль ещё раз*"
                class="modal-body__inp"
                :error="v$.password_confirmation.$error"
            />
        </div>

        <p v-if="errorPasswordText" class="modal-body__error">{{errorPasswordText}}</p>
        <p v-if="matches && form.current_password" class="modal-body__error">Старый и новый пароль совпадают</p>
        <p v-if="!v$.password.$error && v$.password_confirmation.$error && !matches" class="modal-body__error">Ваши пароли не совпадают</p>
        <p v-if="v$.password.$error && !matches" class="modal-body__error">Пароль должен содержать не менее 8 символов</p>


        <div class="modal-body__btns">
            <VButton @click="change" label="Изменить пароль" class="modal-body__btn"/>
            <VButton @click="emit('close')" label="Отменить" bg="#E4E7EE" color="#1E3959" bgHover="#BBC4CD" colorHover="#1E3959" class="modal-body__btn"/>
        </div>
    </div>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import {required, minLength, sameAs} from "@vuelidate/validators";
import {computed, reactive, ref} from "vue";

const props = defineProps({
    errorPasswordText: String
})

const emit = defineEmits(['close', 'change'])

const form = reactive({
    current_password: "",
    password: "",
    password_confirmation: "",
});

const rules = computed(() => ({
    current_password: {required},
    password: {required, minLength: minLength(8)},
    password_confirmation: {required, sameAs: sameAs(form.password)},
}));
const v$ = useVuelidate(rules, form);

const matches = ref(false)
const change = async () => {
    if(form.password === form.current_password) {
        matches.value = true
    } else {
        matches.value = false
    }
    const validate = await v$.value.$validate();
    if (!validate) return;
    if(matches.value) return
    emit('change', form)
}
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    width: 352px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background-color: #fff;

    &__error {
        width: 100%;
        margin-top: 8px;
        color: $red;
        font-size: 16px;
        text-align: left;
    }

    &__close {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    &__title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;

        &:last-child {
            &:active {
                background-color: #8F9CAC !important;
            }
        }

        &:not(:first-child) {
            margin-top: 12px;
        }
    }

    &__form {
        margin-top: 16px;
    }

    &__inp {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }
}
</style>

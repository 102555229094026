<template>
    <div class="v-checkbox"
         :class="{'v-checkbox_disabled': disabled && modelValue, 'v-checkbox_active': modelValue}"
         @click="change"></div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["update:modelValue"]);

const inputVal = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const change = () => {
    if (props.disabled) return
    inputVal.value = !inputVal.value
}
</script>

<style scoped lang="scss">
.v-checkbox {
    position: relative;
    width: 36px;
    height: 20px;
    padding-left: 1.8px;
    padding-right: 1.8px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid $light-gray-2;
    cursor: pointer;

    &:before {
        content: "";
        display: block;
        min-width: 16px;
        min-height: 15.75px;
        border-radius: 100%;
        background-color: $light-gray-2;
        transition: 0.3s;
    }

    &_active {
        border: 1px solid $blue;
        &:before {
            margin-left: 14px;
            background-color: $blue;
        }
    }

    &_disabled {
        opacity: 0.5;
        cursor: default;
    }
}
</style>

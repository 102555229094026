<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close"/>
        </div>

        <div class="modal-body__title">Выход из аккаунта</div>
        <p class="modal-body__subtitle">Вы уверены, что хотите выйти?</p>

        <div class="modal-body__btns">
            <VButton @click="emit('logout')" label="Выйти" bg="#DD4646" bgHover="#D10E0E" class="modal-body__btn"/>
            <VButton @click="emit('close')" label="Остаться" bg="#E4E7EE" color="#1E3959" bgHover="#BBC4CD" colorHover="#1E3959" class="modal-body__btn"/>
        </div>
    </div>
</template>

<script setup>
import VButton from "@/components/UI/VButton/index.vue";

const emit = defineEmits(['close', 'logout'])
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    width: 352px;
    height: 249px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background-color: #fff;

    &__close {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    &__title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
    }

    &__subtitle {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;

        &:first-child {
            &:active {
                background-color: #A61F1F !important;
            }
        }

        &:last-child {
            &:active {
                background-color: #8F9CAC !important;
            }
        }

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}
</style>
